<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.fatura', 2)" icon="mdi-receipt" dark />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-card
          v-if="tipo_data != 'range'"
          outlined
          class="d-flex align-center ml-4"
          height="40px"
          style="overflow: hidden"
        >
          <v-btn @click="add_sub_date = '-1'" tile depressed height="40px">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-divider vertical class="mx-0"></v-divider>
          <v-menu
            ref="menu"
            v-model="menu_picker"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                style="max-width: 130px"
                :value="dateFormated"
                class="text-body-2"
                append-icon="mdi-calendar"
                @click:append="menu_picker = true"
                solo
                flat
                dense
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="changeDate"
              @change="dateChange"
              :type="tipo_data === 'dia' ? 'date' : 'month'"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
          <v-divider class="mx-0" vertical></v-divider>
          <v-btn @click="add_sub_date = '1'" tile depressed height="40px">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card>
        <div
          v-if="tipo_data == 'range'"
          class="mx-4 d-flex align-center justify-center"
        >
          <DataField
            hide_details
            :data_sync.sync="data_inicio"
            :label="$tc('global.inicio')"
          />
        </div>
        <div
          v-if="tipo_data == 'range'"
          class="mx-4 d-flex align-center justify-center"
        >
          <DataField
            hide_details
            :data_sync.sync="data_fim"
            :label="$tc('global.fim')"
            :data_min="data_inicio"
          />
        </div>
        <v-btn-toggle v-model="tipo_data" class="ml-3">
          <v-btn value="dia" class="text-caption" height="40px">
            {{ $tc("global.dia") }}
          </v-btn>
          <v-btn value="mes" class="text-caption" height="40px">
            {{ $tc("global.mes") }}
          </v-btn>
        </v-btn-toggle>
        <v-card flat solo class="ml-3">
          <v-btn
            outlined
            class="text-caption"
            height="40px"
            @click="tipo_data = 'range'"
          >
            {{ $tc("global.livre") }}
          </v-btn>
        </v-card>
        <v-col cols="12" md="2">
          <v-select
            v-model="selectedStatus"
            :label="$tc('global.status')"
            :items="tipos_status"
            dense
            solo
            flat
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          @click="dialogTipoFaturas = true"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="faturas"
          :options.sync="options"
          :server-items-length="totalFaturas"
          :items-per-page="10"
          item-key="id"
          class="data-table data-tables__row-click"
          @click:row="goToFatura"
        >
          <!-- data -->
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>

          <!-- data_emissao -->
          <template v-slot:item.data_emissao="{ item }">
            {{ item.data_emissao | dateFormat("dd/MM/yyyy") }}
          </template>
          <!-- data_emissao -->
          <template v-slot:item.data_impressao="{ item }">
            {{ item.data_impressao | dateFormat("dd/MM/yyyy") }}
          </template>

          <!-- cliente_nome -->
          <template v-slot:item.cliente_nome="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.cliente_nome | excerpt(30) }}
                </div>
              </template>
              <span>{{ item.cliente_nome }}</span>
            </v-tooltip>
          </template>

          <!-- status -->
          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center justify-start">
              <v-sheet
                width="10px"
                height="10px"
                :class="{
                  tocs_blue: item.status === 1,
                  tocs_red: item.status === -1,
                  success: item.status === 3,
                }"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              <span v-if="item.status == -1">
                {{ $t("global.cancelada") }}
              </span>
              <span v-if="item.status == 1">
                {{ $t("global.aberta") }}
              </span>
              <span v-if="item.status == 3">
                {{ $t("global.recebida") }}
              </span>
            </div>
          </template>

          <!-- condpag -->
          <template v-slot:item.condpag="{ item }">
            <div v-if="item.condpag == '1'">{{ $tc("global.aVista") }}</div>
            <div v-if="item.condpag == '2'">{{ $tc("global.aPrazo") }}</div>
          </template>

          <!-- valor_total -->
          <template v-slot:item.valor_total="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.valor_total | guarani }}
            </div>
            <div v-else>
              {{ item.valor_total | currency }}
            </div>
          </template>

          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <div
              v-if="!item.timbrado_manual || item.tipo_fatura == 'NOTA_CREDITO'"
              class="d-flex align-center justify-left"
            >
              <v-btn
                v-if="!item.timbrado_auto_impressor"
                icon
                class="mr-2"
                @click.stop="
                  generateFatura(item.id, false, false, false, item.tipo_fatura)
                "
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  item.timbrado_auto_impressor && item.tipo_fatura == 'FATURAS'
                "
                icon
                class="mr-2"
                @click.stop="
                  generateFaturaAutoImpressor(
                    item.id,
                    false,
                    false,
                    false,
                    item.tipo_fatura
                  )
                "
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>

              <v-btn
                v-if="
                  item.timbrado_auto_impressor &&
                  item.tipo_fatura == 'NOTA_CREDITO'
                "
                icon
                class="mr-2"
                @click.stop="
                  generateNotaCreditoAutoImpressor(
                    item.id,
                    false,
                    false,
                    false,
                    item.tipo_fatura
                  )
                "
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>

              <v-btn
                v-if="!item.timbrado_auto_impressor"
                icon
                class="mr-2"
                @click.stop="
                  generateFatura(item.id, true, false, false, item.tipo_fatura)
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  item.timbrado_auto_impressor && item.tipo_fatura == 'FATURAS'
                "
                icon
                class="mr-2"
                @click.stop="
                  generateFaturaAutoImpressor(
                    item.id,
                    true,
                    false,
                    false,
                    item.tipo_fatura
                  )
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>

              <v-btn
                v-if="
                  item.timbrado_auto_impressor &&
                  item.tipo_fatura == 'NOTA_CREDITO'
                "
                icon
                class="mr-2"
                @click.stop="
                  generateNotaCreditoAutoImpressor(
                    item.id,
                    true,
                    false,
                    false,
                    item.tipo_fatura
                  )
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>

              <v-btn
                icon
                @click.stop="
                  generateFatura(item.id, false, true, false, item.tipo_fatura)
                "
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
            <div v-else>TIMBRADO MANUAL</div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogAddFatura
      v-if="DialogAddFatura"
      :DialogAddFatura.sync="DialogAddFatura"
      @fetch-faturas="getFaturas"
    />
    <DialogAddNotaCreditoManual
      v-if="dialogAddNotaCreditoManual"
      :dialogAddNotaCreditoManual.sync="dialogAddNotaCreditoManual"
      @fetch-faturas="getFaturas"
    />

    <DialogTiposFaturas
      v-if="dialogTipoFaturas"
      :dialogTipoFaturas.sync="dialogTipoFaturas"
      @tipo-fatura="DialogAddFatura = true"
      @tipo-nota-credito-manual="dialogAddNotaCreditoManual = true"
    />

    <DialogFatura
      v-if="DialogFatura"
      :DialogFatura.sync="DialogFatura"
      :fatura_id="fatura_id"
      @fetch-faturas="getFaturas"
    />

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="FATURA"
    />
  </v-container>
</template>

<script>
import { fetchFaturas, dataImpressaoFatura } from "@/api/faturas/faturas.js";
import {
  addDays,
  addMonths,
  format,
  parseISO,
  subDays,
  subMonths,
} from "date-fns";
import { mapState } from "vuex";

const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );

const importFaturaAutoImpressor = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples_auto_impressor.js"
  );

const importNotaCreditoAutoImpressor = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_nota_credito_auto_impressor.js"
  );

export default {
  name: "Faturas",

  components: {
    DialogAddFatura: () => import("./components/DialogAddFatura.vue"),
    DialogAddNotaCreditoManual: () =>
      import("./components/DialogAddNotaCreditoManual.vue"),
    DialogTiposFaturas: () => import("./components/DialogTiposFaturas.vue"),
    DialogFatura: () => import("./components/DialogFatura.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      search: "",
      loading: true,
      faturas: [],
      fatura_id: null,
      totalFaturas: 0,
      options: {
        sortBy: ["data"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15,
      },
      DialogAddFatura: false,
      DialogFatura: false,
      pdfDialog: false,
      pdfData: "",
      dialogTipoFaturas: false,
      dialogAddNotaCreditoManual: false,
      data_inicio: null,
      data_fim: null,
      tipo_data: null,
      add_sub_date: null,
      menu_picker: false,
      changeDate: null,
      tipos_status: ["TODOS", "ABERTO", "CANCELADO"],
      selectedStatus: "TODOS",
    };
  },

  computed: {
    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
    }),

    headers() {
      return [
        {
          text: "ID",
          align: "center",
          value: "id",
          width: "80px",
          sortable: false,
        },
        {
          text: this.$tc("global.numero"),
          align: "center",
          value: "numero",
          width: "113px",
        },
        {
          text: this.$t("global.data"),
          value: "data",
          width: "90px",
        },
        {
          text: this.$tc("global.data-emissao", 2),
          value: "data_emissao",
          width: "140px",
          sortable: true,
        },
        {
          text: this.$tc("global.data") + " " + this.$tc("global.impressao"),
          value: "data_impressao",
          width: "150px",
        },

        {
          text: this.$tc("global.nome"),
          value: "cliente_nome",
          sortable: false,
        },
        {
          text: "Status",
          width: "105px",
          value: "status",
          sortable: false,
        },
        {
          text: "Tipo",
          width: "105px",
          value: "tipo_fatura",
          sortable: false,
        },

        {
          text: this.$tc("global.pagamento"),
          value: "condpag",
          width: "100px",
          sortable: false,
        },

        {
          text: this.$tc("global.moeda"),
          align: "right",
          value: "moeda",
          width: "90px",
          sortable: false,
        },
        {
          text: this.$tc("global.total"),
          value: "valor_total",
          align: "right",
          width: "120px",
          divider: true,
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          width: "170px",
          sortable: false,
        },
      ];
    },

    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      if (this.search && this.search.length > 3) {
        queryString += `&search=${this.search}`;
      }
      if (this.tipo_data != undefined && this.tipo_data != "range") {
        queryString += `&tipo_data=${this.tipo_data}`;
        if (this.changeDate) {
          if (this.tipo_data == "dia") {
            queryString += `&change_date=${format(
              parseISO(this.changeDate),
              "yyyy-MM-dd"
            )}`;
          } else if (this.tipo_data == "mes") {
            queryString += `&change_date=${format(
              parseISO(this.changeDate),
              "yyyy-MM"
            )}`;
          }
        }
      }
      if (this.tipo_data != undefined && this.tipo_data == "range") {
        queryString += `&tipo_data=${this.tipo_data}`;
        if (this.data_inicio) {
          queryString += `&data_inicio=${this.data_inicio}`;
        }
        if (this.data_fim) {
          queryString += `&data_fim=${this.data_fim}`;
        }
      }

      if (this.selectedStatus && this.selectedStatus != "TODOS") {
        queryString += `&status=${this.selectedStatus}`;
      }
      return `${queryString}`;
    },

    dateFormated() {
      let result = "";

      if (!this.changeDate) {
        return "";
      }

      if (this.tipo_data === "dia") {
        result = format(parseISO(this.changeDate), "dd/MM/yyyy");
      }
      if (this.tipo_data === "mes") {
        result = format(parseISO(this.changeDate), "MM/yyyy");
      }

      return result;
    },

    now() {
      let result = "";

      if (this.tipo_data === "dia") {
        result = format(new Date(), "yyyy-MM-dd");
      }
      if (this.tipo_data === "mes") {
        result = format(new Date(), "yyyy-MM");
      }
      if (this.tipo_data === "range") {
        result = format(new Date(), "yyyy-MM-dd");
      }

      return result;
    },
  },

  watch: {
    url() {
      this.getFaturas();
    },

    // search() {
    //   if (this.search && this.search.length > 3) {
    //     this.getFaturas();
    //   }
    // },

    options: {
      handler() {
        this.getFaturas();
      },
      deep: true,
    },

    data_inicio() {
      if (this.data_inicio > this.data_fim) {
        this.data_fim = this.data_inicio;
      }
    },
    add_sub_date() {
      if (this.add_sub_date) {
        if (this.add_sub_date === "-1") {
          if (this.tipo_data === "dia") {
            this.changeDate = format(
              subDays(parseISO(this.changeDate), 1),
              "yyyy-MM-dd"
            );
          } else {
            this.changeDate = format(
              subMonths(parseISO(this.changeDate), 1),
              "yyyy-MM"
            );
          }
        }
        if (this.add_sub_date === "1") {
          if (this.tipo_data === "dia") {
            this.changeDate = format(
              addDays(parseISO(this.changeDate), 1),
              "yyyy-MM-dd"
            );
          } else {
            this.changeDate = format(
              addMonths(parseISO(this.changeDate), 1),
              "yyyy-MM"
            );
          }
        }
        // this.getFaturas();
      }
      this.add_sub_date = null;
    },
    tipo_data() {
      if (this.tipo_data === "dia") {
        this.changeDate = format(this.changeDate, "yyyy-MM-dd");
      }
      if (this.tipo_data === "mes") {
        this.changeDate = format(this.changeDate, "yyyy-MM");
      }
      // this.getFaturas();
    },
  },

  methods: {
    async getFaturas() {
      try {
        this.loading = true;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const response = await fetchFaturas(
          `?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}` +
            this.url
        );
        this.faturas = response.data;
        this.totalFaturas = response.total;
        if (this.options.itemsPerPage != Number(response.per_page)) {
          this.options.itemsPerPage = Number(response.per_page);
        }

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },

    goToFatura(item) {
      this.fatura_id = item.id;
      this.DialogFatura = true;
    },

    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }

      try {
        importFatura()
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async generateFaturaAutoImpressor(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }

      try {
        importFaturaAutoImpressor()
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async generateNotaCreditoAutoImpressor(
      fatura_id,
      print,
      download,
      unica,
      tipo
    ) {
      this.loading = true;

      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }

      try {
        importNotaCreditoAutoImpressor()
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    dateChange() {
      if (this.changeDate != this.now) {
        this.getFaturas();
      }
    },
  },
  mounted() {
    // this.getFaturas();
    this.tipo_data = "dia";
    this.changeDate = this.now;
  },
};
</script>

<style lang="scss" scoped></style>
